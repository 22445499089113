import React, { FC, useMemo } from 'react';
import type { TranslationGetter } from '@wix/editor-elements-types/thunderbolt';

import {
  LOGIN_SOCIAL_BAR_NAMESPACE,
  CHOOSE_OPTION_TEXT_DEFAULT,
  OPTION_VALUE_TEXT,
  OPTION_VALUE_CHOOSE,
  OPTION_VALUE_LOG_OUT,
} from '../../../constants';
import { ILoginSocialBarProps } from '../../../LoginSocialBar.types';
import {
  BIAction,
  getMembersAreaNavigationEvent,
  MEMBERS_APP_UOU_ENDPOINT,
} from '../../bi/events';
import styles from './LoginSocialBarNativeMenu.scss';

type ILoginSocialBarNativeMenuProps = Pick<
  ILoginSocialBarProps,
  | 'menuItems'
  | 'onLogout'
  | 'logOutText'
  | 'currentPrimaryPageHref'
  | 'navigateTo'
  | 'reportBi'
> & {
  translate?: TranslationGetter;
  ariaLabel: string;
};

export const LoginSocialBarNativeMenu: FC<ILoginSocialBarNativeMenuProps> = ({
  ariaLabel,
  menuItems,
  logOutText,
  currentPrimaryPageHref,
  onLogout,
  navigateTo,
  translate,
  reportBi,
}) => {
  const optionValueText = translate!(
    LOGIN_SOCIAL_BAR_NAMESPACE,
    OPTION_VALUE_TEXT,
    CHOOSE_OPTION_TEXT_DEFAULT,
  );

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const { value } = event.target;

    if (value === OPTION_VALUE_CHOOSE) {
      return;
    }

    if (value === OPTION_VALUE_LOG_OUT) {
      onLogout();
      return;
    }

    const { link, label } = menuItems[parseInt(value, 10)];
    navigateTo(link);

    reportBi(
      getMembersAreaNavigationEvent({
        action: BIAction.ItemClick,
        pageName: label,
      }),
      {
        endpoint: MEMBERS_APP_UOU_ENDPOINT,
      },
    );
  };

  const selectedValue = useMemo(() => {
    const selectedMenuItemIndex = menuItems.findIndex(
      ({ link }) => link.href && link.href === currentPrimaryPageHref,
    );
    return selectedMenuItemIndex !== -1
      ? selectedMenuItemIndex
      : OPTION_VALUE_CHOOSE;
  }, [currentPrimaryPageHref, menuItems]);

  return (
    <select
      aria-label={ariaLabel}
      className={styles.root}
      value={selectedValue}
      onChange={handleChange}
    >
      <option value={OPTION_VALUE_CHOOSE}>{optionValueText}</option>
      {menuItems.map(({ label, displayCount }, index) => (
        <option key={index} value={index}>
          {label}
          {typeof displayCount === 'number' && ` (${displayCount})`}
        </option>
      ))}
      <option value={OPTION_VALUE_LOG_OUT}>{logOutText}</option>
    </select>
  );
};
