import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';

const mapActionsToProps: IComponentController = ({ updateProps, reportBi }) => {
  return {
    onMenuOpen: () => {
      updateProps({
        isMenuOpen: true,
      });
    },
    onMenuClose: () => {
      updateProps({
        isMenuOpen: false,
      });
    },
    reportBi,
  };
};

export default mapActionsToProps;
